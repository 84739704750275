import React from 'react'
import Blog1 from "../images/blog-5-1.jpg"
import Blog2 from "../images/biology-class-1200x800.jpg"
import Blog3 from "../images/art-class-1200x800.jpg"
import Blog4 from "../images/blog-5-1.jpg"
import Blog5 from "../images/history-class.jpg"
function News() {
  return (
<>
<div className=" py-5 d-flex ">
        <div class="container-fluid">
          <div class="container  ">
            <div class="mx-auto text-center  " >
              <h1 class=" mb-5 " style={{color:"#666666", fontSize:"3rem" ,fontWeight:"bold"}}>News & Update</h1>
      

            </div>
            <div class="row mt-5 ">
              <div class="col-md-6 col-lg-4 col-12  " data-wow-delay="0.1s">
              <div class="product-item1  fadeIn h-100 ">
                  <img src={Blog1} class="img-fluid border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4 class="mt-2"  style={{color:"#666666"}}>Interdum et malesuada fames acanter</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus...</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
              <div class="product-item1  fadeIn h-100 ">
                  <img src={Blog2} class="img-fluid  border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4  style={{color:"#666666"}}>Class aptent taciti sociosqu litora torquent</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
              <div class="product-item1  fadeIn h-100 ">
                <img src={Blog3} class="img-fluid  border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4  style={{color:"#666666"}}>Quisque pretium felis mauris sed blandit</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
              <div class="product-item1  fadeIn h-100 ">
                  <img src={Blog4} class="img-fluid  border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4  style={{color:"#666666"}}>Quisque pretium felis mauris sed blandit</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
              <div class="product-item1  fadeIn h-100 ">
                <img src={Blog5} class="img-fluid  border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4  style={{color:"#666666"}}>Quisque pretium felis mauris sed blandit</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
                <div class="product-item1  fadeIn h-100 ">
                <img src={Blog3} class="img-fluid  border-radius" />

                  <div class=" text-black-50 mb-2">
                    <h4  style={{color:"#666666"}}>Quisque pretium felis mauris sed blandit</h4>
                    <p class=""  style={{color:"#666666"}}>posted on September 07, 2017</p>
                    <p  style={{color:"#666666"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>



</>
  )
}

export default News