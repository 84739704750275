
import './App.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
 import Footer from './components/Footer'
 import Home from "./pages/Home"
import About from "./pages/About"
import News from './pages/News';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';


  
function App() {
  return (
   <>
   
   <BrowserRouter>

<Navbar />

<Routes>
<Route path='/' element={<Home />} />
<Route path='/about' element={<About />} />
<Route path='/news' element={<News />} />
<Route path='/gallery' element={<Gallery />} />
<Route path='/contact' element={<Contact />} />
</Routes>
<Footer/>

</BrowserRouter>
   
   
   </>
  );
}

export default App;
