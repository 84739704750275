import React from 'react'
import "../css/Gallery.css"
import Guru1 from "../images/history-class.jpg"
import Guru2 from "../images/blog-5-1.jpg"
import Guru3 from "../images/art-class-1200x800.jpg"
import Guru4 from "../images/sport-class-400x400.jpg"
import Guru5 from "../images/blog-3-1-400x400.jpg"
import Guru6 from "../images/blog-2-1-400x400.jpg"
import Guru7 from "../images/blog-1-1-400x400.jpg"
import Guru8 from "../images/bg-school-400x400.jpg"
import Guru9 from "../images/biology-class-1200x800.jpg"
import Guru10 from "../images/math-class-400x400.jpg"
import Guru11 from "../images/blog-6-1-400x400.jpg"
import Guru12 from "../images/blog-4-400x400.jpg"
function Gallery() {
  return (
 <>
  
  <div class="container-fluid project py-5 mb-5">
      <div class="container">
        <div
          class="text-center mx-auto pb-5 wow fadeIn"
          data-wow-delay=".3s"
       style={{maxWidth:"600px"}}
        >
          
          <h1>Our Gallery</h1>
        </div>
        <div class="row g-3">
         
         
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".3s">
          <div class="product-item  ">
             
              <img src={Guru4} class="img-fluid w-100  border-radius"
                  alt=""/>
                
            
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".5s">
          <div class="product-item  ">
             
              <img src={Guru5} class="img-fluid w-100  border-radius"
                  alt=""/>
                
             
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
             
              <img src={Guru6} class="img-fluid w-100  border-radius"
                  alt=""/>
              
            
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
             
              <img src={Guru7} class="img-fluid w-100 border-radius"
                  alt=""/>
             
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
             
              <img src={Guru8} class="img-fluid w-100  border-radius"
                  alt=""/>
              
           
            </div>
          </div>
        
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
             
              <img src={Guru10} class="img-fluid w-100  border-radius"
                  alt=""/>
              
             
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
         
              <img src={Guru11} class="img-fluid w-100  border-radius"
                  alt=""/>
              
             
            </div>
          </div>
          <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay=".7s">
          <div class="product-item  ">
         
              <img src={Guru12} class="img-fluid w-100  border-radius"
                  alt=""/>
              
            
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5  mt-3" style={{backgroundColor:"#23a455"}}>
        <div class="container">
        <div  class="text-center mx-auto pb-5 wow fadeIn" >
          <h1 style={{color:"#ffffff",fontSize:"40px", fontWeight:"bold"}}>Contact us if you are interested.</h1>
          <button  class="btn py-2 px-4 bg-light text-primary border mt-3" href="">CONTACT US</button>
        </div>
       
        </div>
      </div>
 </>
  )
}

export default Gallery