import React, { useState } from 'react'
import "../css/About.css"
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import Blog2 from "../images/headmaster.jpg"
import About1 from "../images/blog-5-1.jpg"
import Guru1 from "../images/guru1-500x650.jpg"
import Guru2 from "../images/guru2-500x650.jpg"
import Guru3 from "../images/guru5-500x650.jpg"
import Guru4 from "../images/guru3-500x650.jpg"
import Guru5 from "../images/guru4-500x650.jpg"
import Guru6 from "../images/guru6-500x650.jpg"
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
function About() {
    const [counterState,setCounterState] = useState(false)
  return (
    <>
      <div class="container-fluid py-5"style={{backgroundColor:"#fafafa"}} >
        <div class="container">
          <div class="row g-5 p-5">
            <div class="col-lg-4   " data-wow-delay="0.1s" >
              
            <div class="product-item fadeIn h-100 p-4 ">
              <img src={Blog2} class="img-fluid about-img border-radius" />
             
              <Nav.Link><Link to="/" class="btn border-0 py-2 px-4 text-primary border" href="">HEADMASTER</Link></Nav.Link>
              <h4  class="text-common mb-4">Michel Groch</h4>
            </div>
            </div>
            <div class="col-lg-8 " data-wow-delay="0.5s">
              <Nav.Link><Link to="/" class="btn border-0 py-2 px-4 text-primary border" href="">WELCOME</Link></Nav.Link>
              <h1 class="text-common mb-4">Hello. Our school has been present for over 20 years. We make the most of all our students.</h1>
              <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pulvinar tellus sed mauvehicula tempor. In hac habitasse platea dictumst. Donec nunc nunc, interdum sed aliquet quis, conditum vitae enim. Quisque molestie consectetur urna quis scelerisque. Morbi at lectus sapien. Donec fgiat arcu in mi placerat ullamcorper.</p>
              <p class="mb-4">In tempor, ex id viverra convallis, ante augue vestibulum leo, eget elementum risus nulla bibendum nisl. Maecenas lacinia libero tincidunt justo bibendum sagittis. Duis gravida massa ac vehicula pulvinar. Nunc ligula orci, vulputate id tempor ac, tincidunt in orci. Etiam nulla lectus, commodo quis tortor et, congue volutpat velit..</p>

            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid py-5" >
        <div class="container">
          <div class="row g-5 p-5">
            <div class="col-lg-6  " data-wow-delay="0.1s" >
            <div class="product-item fadeIn h-100 p-4 ">
              <img src={About1} class="img-fluid about-img border-radius" />
              </div>
            </div>
            <div class="col-lg-6 " data-wow-delay="0.5s">
              <Nav.Link><Link to="/" class="btn border-0 py-2 px-4 text-primary border" href="">VISION</Link></Nav.Link>
              <h1 class="text-common mb-4">Doing the right thing, at the right time.</h1>
              <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pulvinar tellus sed mauvehicula tempor. In hac habitasse platea dictumst. Donec nunc nunc, interdum sed aliquet quis, conditum vitae enim. Quisque molestie consectetur urna quis scelerisque. Morbi at lectus sapien. Donec fgiat arcu in mi placerat ullamcorper.</p>
            

            </div>
            
            <div class="col-lg-6 " data-wow-delay="0.5s">
              <Nav.Link><Link to="/" class="btn border-0 py-2 px-4 text-primary border" href="">MISION</Link></Nav.Link>
              <h1 class="text-common mb-4">We make the most of all our students.</h1>
              <p class="mb-4">Duis vel erat quis justo vehicula vulputate sit amet nec nisi. Fusce a venenatis arcu, vel varius mi. Donec elit lacus, fringilla placerat aliquet sed, eleifend ac tortor. Morbi lectus mi, imperdiet a metus vel, consectetur blandit est. .</p>
            

            </div>
            <div class="col-lg-6   " data-wow-delay="0.1s" >
            <div class="product-item fadeIn h-100 p-4 ">
              <img src={About1} class="img-fluid about-img border-radius" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid py-5  mt-3" style={{backgroundColor:"#23a455"}}>
        <div class="container">
           <ScrollTrigger   onEnter={()=>setCounterState(true)} onExit={()=>setCounterState(false)}>
                
                
            <div class="row g-5 py-5 ">
            <div class="col-md-6 col-lg-3  col-12 wow ">
              <div class=" d-flex   justify-content-center">
                <div class="TechnicalSupport3">
                { counterState &&
                     <h2 className=''>
                     <CountUp start={0}
                      end={21}
                      duration={2.75}
                      >
                      </CountUp>
                     </h2>
                   }
                </div>

              </div>
              <div class="element-counter text-center">
                <h4 class="mt-3">Loving Teachers</h4>
              
              </div>
            </div>
            <div class="col-md-6 col-lg-3  col-12 wow ">
              <div class=" d-flex   justify-content-center">
                <div class="TechnicalSupport3">
                { counterState &&
                     <h2 className=''>
                     <CountUp start={0}
                      end={47}
                      duration={2.75}
                      >
                      </CountUp>
                     </h2>
                   }
                </div>
              </div>
              <div class="element-counter text-center">
                <h4 class="mt-3">English Lessons</h4>
              
              </div>
            </div>
            <div class="col-md-6 col-lg-3  col-12 wow ">
              <div class=" d-flex   justify-content-center">
                <div class="TechnicalSupport3">
                { counterState &&
                     <h2 className=''>
                     <CountUp start={0}
                      end={120}
                      duration={2.75}
                      >
                      </CountUp>
                     </h2>
                   }
                </div>
              </div>
              <div class="element-counter text-center">
                <h4 class="mt-3">Outdoor Activities</h4>
              
              </div>
            </div>
            <div class="col-md-6 col-lg-3  col-12 wow ">
              <div class=" d-flex   justify-content-center">
                <div class="TechnicalSupport3">
                { counterState &&
                     <h2 className=''>
                     <CountUp start={0}
                      end={96}
                      duration={2.75}
                      >
                      </CountUp>
                     </h2>
                   }
                </div>
              </div>
              <div class="element-counter text-center">
                <h4 class="mt-3">Fun Experiments</h4>
              
              </div>
              <div>
                <h1></h1>
              </div>
            </div>
          </div>
          </ScrollTrigger>
       
        </div>
      </div>
    
      <div class="container-fluid project py-5 mb-5">
      <div class="container">
        <div
          class="text-center mx-auto pb-5 wow fadeIn"
          data-wow-delay=".3s"
       style={{maxWidth:"600px"}}
        >
          
          <h1>Teachers</h1>
        </div>
        <div class="row g-5">
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru1} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                  <div class="text-center teacher-info">
                    <h4>Andy Jones</h4>
                    <p class="m-0 text-white">Biology Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru2} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                <div class="text-center teacher-info">
                    <h4>Kaylin Moore</h4>
                    <p class="m-0 text-white">English Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru3} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                <div class="text-center teacher-info">
                     <h4>Miranda Stipler</h4>
                    <p class="m-0 text-white">Art Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru4} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                <div class="text-center teacher-info">
                    <h4>Mandy Jackson</h4>
                    <p class="m-0 text-white">Math Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru5} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                <div class="text-center teacher-info">
                    <h4>Olivia Mitchell</h4>
                    <p class="m-0 text-white">History Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
            <div class="project-item">
              <div class="project-img">
              <img src={Guru6} class="img-fluid w-100 rounded"
                  alt=""/>
                <div class="project-content">
                <div class="text-center teacher-info">
                    <h4>Edward Sanders</h4>
                    <p class="m-0 text-white">Code Teacher</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>
  )
}

export default About