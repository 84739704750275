import React from 'react'
import "../css/Home.css"
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import Blog2 from "../images/intro-school-green.png"
import Biology from "../images/biology-class-1200x800.jpg"
import ArtClass from "../images/art-class-1200x800.jpg"
import Guru1 from "../images/guru1-500x650.jpg"
import Guru2 from "../images/guru2-500x650.jpg"
import Guru3 from "../images/guru5-500x650.jpg"
import Client1 from "../images/client1-1.jpg"
import Client2 from "../images/client2-1.jpg"
import Client3 from "../images/client3-1.jpg"
import Client4 from "../images/client4-1.jpg"
import Client5 from "../images/client5-1.jpg"
import Client6 from "../images/client6-1.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faAward, faChartBar } from '@fortawesome/free-solid-svg-icons'
function Home() {
  return (
    <>
      <div className="admission ">
        <div className="col-lg-12 ">

          <div className="row g-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1 class="contact-us text-center ">OPENING UP A WORLD OF EDUCATION</h1>
              <p class=" get-contact text-center">We believe that there is nothing more important than skillful education. Doing the right thing, at the right time.</p>
            </div>

          </div>

        </div>

      </div>
      <div class="container-fluid py-5" >
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6  " data-wow-delay="0.1s" >
              <img src={Blog2} class="img-fluid about-img" />
            </div>
            <div class="col-lg-6 " data-wow-delay="0.5s">
              <Nav.Link><Link to="/" class="btn btn-primary py-2 px-4 bg-light text-primary border" href="">HISTORY</Link></Nav.Link>
              <h1 class="text-common mb-4">Quality Children Education</h1>
              <p class="mb-4">Alterum accommodare duo cu. Ius labore luptatum efficiendi ex, ne vim enim rebum honestatis, ad his consulatu pertinacia deterruisset. Te bonorum ancillae nec. Mea errem alterum in, harum iudico vel et, nec atqui propriae id.</p>
              <p class="mb-4">Alterum accommodare duo cu. Ius labore luptatum efficiendi ex, ne vim enim rebum honestatis, ad his consulatu pertinacia deterruisset.</p>

            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid py-5  mt-3" >
        <div class="container">

          <div class="row ">
            <div class="col-md-6 col-lg-12">
              <div class="product-item1  ">
                <div class="row mb-5 ">
                  <div class="col-md-6 col-lg-4  col-12 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faAward} className="fa-3x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">National Awards</h4>
                        <h6 class=" mt-2 ">Lorem ipsum gravida nibh vel velit auctor aliqunean sollicitudinlor quisbibendum auci elit consequat ipsutis sem nibh id elitsed vulputate.</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4  col-12 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faGraduationCap} className="fa-3x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">Best Teachers</h4>
                        <h6 class="mt-2 ">Lorem ipsum gravida nibh vel velit auctor aliqunean sollicitudinlor quisbibendum auci elit consequat ipsutis sem nibh id elitsed vulputate.</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4 col-12 wow ">
                    <div class=" d-flex">
                      <div class="TechnicalSupport">
                        <FontAwesomeIcon icon={faChartBar} className="fa-3x text-center" />
                      </div>
                      <div class="commen-benefits">
                        <h4 class="text-common ">Many Courses</h4>
                        <h6 class="mt-2 ">Lorem ipsum gravida nibh vel velit auctor aliqunean sollicitudinlor quisbibendum auci elit consequat ipsutis sem nibh id elitsed vulputate.</h6>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="admission1 py-5 ">
        <div class="container-fluid">
          <div class="container  ">
            <div class="mx-auto text-center  " >
              <h1 class="text-white mb-5 ">News & Update</h1>

            </div>
            <div class="row g-4  mt-5 element-common">
              <div class="col-md-6 col-lg-4 col-12  " data-wow-delay="0.1s">
                <div class="product-item  fadeIn h-100 p-4">
                  <img src={Biology} class="img-fluid" />

                  <div class=" text-black-50 mb-2">
                    <h4 class="text-white">Interdum et malesuada fames acanter</h4>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
                <div class="product-item  fadeIn h-100 p-4">
                  <img src={ArtClass} class="img-fluid" />

                  <div class=" text-black-50 mb-2">
                    <h4 class="text-white">Class aptent taciti sociosqu litora torquent</h4>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " data-wow-delay="0.1s">
                <div class="product-item  fadeIn h-100 p-4">
                  <img src={Biology} class="img-fluid  "/>

                  <div class=" text-black-50 mb-2">
                    <h4 class="text-white">Interdum et malesuada fames acanter</h4>
                    <p class="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus..</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
     
        
   
           
          
        
      

    
      <div class="container-fluid py-5" >
        <div class="container">
          <div class="row g-5 ">
            <div class="col-lg-6  " data-wow-delay="0.1s" >
              <h1 class="text-gray">Our Teachers</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit odio ante, id dapibus elit condimentum vitae. Maecenas eget orci vitae enim accumsan mollis.</p>
            </div>
            <div class="row g-4  mt-5 ">
              <div class="col-md-6 col-lg-4 ">
                <div class="product-item  fadeIn h-100 p-4">
                  <img src={Guru1} class="img-fluid border-radius" />
                

                </div>
              </div>
              <div class="col-md-6 col-lg-4 " >
                <div class="product-item  fadeIn h-100 p-4">
                  <img src={Guru2} class="img-fluid border-radius" />



                </div>

              </div>
              <div class="col-md-6 col-lg-4">
        <div class="product-item fadeIn h-100 p-4">
            <img src={Guru3} class="img-fluid border-radius" />
        </div>
    </div>
            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid py-5 abhishek" >
                <div class="container">
                    <div class="row g-5 ">
                    <div class="col-lg-12 testimonals-partener">
                           
                           <h1 >Testimonials & Partner</h1>
                          

                       </div>
                        <div class="col-lg-6 testimonals-partener">
                           
                           
                            <p class="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquet, erat non malesuada consequat, nibh erat tempus risus, vitae porttitor purus nisl vitae purus. Praesent tristique odio ut rutrum pellentesque. Fusce eget molestie est, at rutrum est.</p>

                        </div>
                        <div class="col-lg-6  " >
                        <div class="row g-4 ">
              <div class="col-md-6 col-lg-4  col-6 " >
                <div class="product-item ">
                  <img src={Client1} class="img-fluid" />
                 

                </div>
              </div>
              <div class="col-md-6 col-lg-4  col-6" >
                <div class="product-item">
                  <img src={Client2} class="img-fluid" />
                 

                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-6 ">
                <div class="product-item  ">
                  <img src={Client3} class="img-fluid" />
                 

                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-6">
                <div class="product-item ">
                  <img src={Client4} class="img-fluid" />
                 

                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-6 ">
                <div class="product-item ">
                  <img src={Client5} class="img-fluid" />



                </div>

              </div>
              <div class="col-md-6 col-lg-4 col-6">
                <div class="product-item ">
                  <img src={Client6} class="img-fluid" />



                </div>
              </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Home